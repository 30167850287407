export const validateEmailReg =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const REACT_APP_PUBLIC_URL = `${process.env.PUBLIC_URL}/`;
export const REACT_APP_POSTCODE_API_KEY =
  process.env.REACT_APP_POSTCODE_API_KEY;

export const BLOCKED_DATES_LIST = [
  '2024-12-23',
  '2024-12-24',
  '2024-12-25',
  '2024-12-26',
  '2024-12-27',
  '2024-12-28',
  '2024-12-29',
  '2024-12-30',
  '2024-12-31',
  '2025-01-01',
  '2025-01-02',
  '2025-04-18',
  '2025-04-21',
  '2025-05-05',
  '2025-05-26',
  '2025-08-25',
];

export const DEFAULT_PHONE_COUNTRY = 'GB';
export const DEFAULT_COMPANY_TAG = 'hire';

export const MOBILE_SCREEN = 575;
export const SMALL_SCREEN = 767;
export const LAPTOP_SCREEN = 991;
export const DESKTOP_SCREEN = 1200;

export const COMPANY_PHONE_NUMBER_CALL = '01182305303';
export const COMPANY_PHONE_NUMBER = '0118 230 5303';

export const BIG_PRODUCT_CARD_FORMAT = 'big';
export const SMALL_PRODUCT_CARD_FORMAT = 'small';

export const regions = [
  { name: 'East Midlands' },
  { name: 'East of England' },
  { name: 'England London' },
  { name: 'England North East' },
  { name: 'England North West' },
  { name: 'Scotland' },
  { name: 'South East England' },
  { name: 'South West England' },
  { name: 'Wales' },
  { name: 'West Midlands' },
  { name: 'Yorkshire' },
];

export const categories = [{ name: 'Skip hire', slug: 'skip-hire' }];

export const isProdEnv =
  window.location.hostname !== 'localhost' &&
  !window.location.hostname.includes('stg-');

export const productFaqs = [
  {
    question: 'How do I book a skip online?',
    answer:
      'Booking a skip with us is easy! Just follow these simple steps:\n' +
      '\n' +
      '1. Enter Your Postal Code: Start by providing your postal code to confirm service availability in your area.\n' +
      '2. Select Delivery Type: Choose the type of delivery that suits your needs.\n' +
      '3. Choose Permit Option: Decide if you need a permit for placing the skip on public property and select the appropriate option.\n' +
      '4. Specify Hiring Period: Set the duration for which you need the skip.\n' +
      "5. Click 'Book Now': Proceed by clicking the 'Book Now' button to finalize your choices.\n" +
      '6. Provide Contact Details: Enter your contact information so we can reach you regarding your booking.\n' +
      '7. Pay Online: Complete your booking by paying securely online.\n' +
      '\nOur straightforward online process ensures your skip is booked in just a few clicks!',
  },
  {
    question: 'Do I need a permit to place a skip on a public highway?',
    answer:
      'Placing a skip on a public highway or council property often necessitates obtaining a skip permit for safety reasons.\n\n' +
      'Typically, the supplier will handle the permit arrangements. However, some local authorities may require the skip hirer to apply for the permit directly. You will receive detailed guidance on this during the ordering process.\n\n' +
      'Please be aware that delivery of your skip will not proceed until the permit has been secured, which may take up to three business days.',
  },
  // {
  //   question: 'What sizes of skips are available for hire?',
  //   answer:
  //     "We offer a wide range of skips to suit various project sizes and waste disposal needs. Our skips are available in sizes from 4 yards to 19 yards. Whether you're clearing out a small room, renovating a large property, or undertaking a commercial construction project, we have the perfect size skip for you. Choose the right one based on the volume of waste you expect to generate.",
  // },
  {
    question: 'What can I dispose of in a skip?',
    answer:
      'Our skips are suitable for a variety of waste types commonly generated from household, garden, and construction projects. You can dispose of materials such as soil, wood, metal, concrete, furniture, and non-electrical fittings. However, there are restrictions on certain items for safety and environmental reasons. Prohibited items include hazardous waste, electrical items, asbestos, tires, refrigerators, freezers, air conditioners, fluorescent tubes, batteries, paints and solvents, and medical waste.',
  },

  {
    question: 'How long can I hire a skip for?',
    answer:
      'The maximum hire period included in the initial price is 14 days. If you require the skip for a longer period, extensions are available upon request. Please note that extending the hire period incurs additional costs, which are determined on a case-by-case basis. For more details or to arrange an extension, please contact us directly.',
  },
  // {
  //   question: 'What is the cost of hiring a skip?',
  //   answer:
  //     'The cost of hiring a skip varies depending on several factors including the size of the skip, the duration of the hire, your location, and whether you require a permit for placing the skip on public land. Prices start from a competitive rate for our smallest skip and adjust according to size and other requirements. For an accurate quote tailored to your specific needs, please enter your postal code on our website, select the appropriate options, and view the pricing details. This ensures you receive the most up-to-date and relevant pricing information.',
  // },
  {
    question: 'How far in advance do I need to book a skip?',
    answer:
      'We recommend booking your skip as early as possible to ensure availability and to accommodate your project schedule. For standard deliveries, booking at least 24 hours in advance is advisable. If you require a permit for placing the skip on a public highway, please allow additional time, as permit acquisition can take anywhere from 3 to 7 days depending on local council regulations. Booking early helps avoid any delays and ensures your skip arrives when you need it.',
  },
  // {
  //   question: 'What happens if I need to extend the hire period?',
  //   answer:
  //     'Please note that extending the hire period incurs additional costs, which are determined on a case-by-case basis. For more details or to arrange an extension, please contact us directly.',
  // },
  // {
  //   question: 'Is there a weight limit for the skip?',
  //   answer:
  //     "Yes, each skip size has a maximum weight limit to ensure safe transportation and compliance with vehicle regulations. It's important to be aware of these limits when filling your skip, especially with heavy materials like soil or concrete. Exceeding the weight limit can result in additional charges or require the removal of excess waste to lighten the load. Specific weight limits for each skip size are available on our website on the skip page “Product Details“ section",
  // },
  // {
  //   question: 'How do I prepare for the skip delivery?',
  //   answer:
  //     'Preparing for skip delivery ensures a smooth process and helps avoid any complications. Here are some tips:\n\n' +
  //     '1. Clear Space: Ensure there is sufficient space for the skip on your property or the designated delivery area. The space should be wide enough to accommodate the skip and accessible for our delivery truck.\n' +
  //     '2. Consider Access: The delivery area must be accessible, with no obstructions such as low hanging wires, narrow gates, or parked vehicles that might hinder the delivery truck.\n' +
  //     '3. Protect Surfaces: If you are concerned about damage to driveways or other surfaces, consider laying down protective materials like plywood or boards where the skip will be placed.\n' +
  //     '4. Obtain Permissions: If the skip needs to be placed on public land, ensure you have the necessary permit in place before delivery.\n\n' +
  //     'By following these steps, you can help ensure that your skip delivery goes smoothly and without delay.',
  // },
  // {
  //   question: 'What should I do if my skip is not picked up on time?',
  //   answer:
  //     'If your skip has not been collected on the scheduled pickup date, please contact us as soon as possible. We strive to maintain timely pickups, but occasionally, delays can occur due to unforeseen circumstances. When you reach out, provide your booking details, and we will prioritize resolving the issue promptly. We appreciate your patience and will work diligently to ensure your skip is collected without further delay.',
  // },
];
