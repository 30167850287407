import { useEffect } from 'react';
import { useStore } from 'effector-react';
import { sha1 } from 'js-sha1';
import { $orderCompleted } from 'models/orders';
import { getCookie } from 'utils/getCookie';

export const useImpactTrackConversion = () => {
  const order = useStore($orderCompleted);

  useEffect(() => {
    try {
      if (window.ire && order) {
        const items = order.products.reduce((acc, el) => {
          acc.push({
            subTotal: el.total,
            category: 'skip',
            sku: el.id,
            quantity: el.quantity,
            name: el.name,
          });

          if (el.extra) {
            el.extra.forEach((el) => {
              acc.push({
                subTotal: el.total,
                category: el.slug,
                sku: el.id,
                quantity: el.quantity,
                name: el.value,
              });
            });
          }

          return acc;
        }, []);

        const data = {
          clickId: getCookie('irclickid'),
          orderId: order.orderId,
          customerId: '',
          customerEmail: order.user.email ? sha1(order.user.email) : '',
          customerStatus: 'New',
          currencyCode: 'GBP',
          orderPromoCode: order.userPromo || '',
          orderDiscount: order.orderDiscount,
          items,
        };

        window.ire('trackConversion', 45530, data, {
          verifySiteDefinitionMatch: true,
        });
      }
    } catch (e) {
      console.warn(e);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
