import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { toast } from 'react-toastify';
import Hotjar from '@hotjar/browser';
import { isProdEnv } from 'consts';
import { useGate } from 'effector-react';
import { useGetLayoutConfiguration } from 'hooks';
import { productsGate } from 'models/product';

import { ChristmasModal } from 'features/ChristmasModal/ChristmasModal';
import { Meta } from 'features/Meta';

import { Footer } from './Footer';
import { Header } from './Header';

import 'react-toastify/dist/ReactToastify.css';
import style from './layout.module.scss';

toast.configure({
  autoClose: 3000,
  position: 'bottom-right',
});

export const Layout = ({ children }) => {
  useGate(productsGate);

  useEffect(() => {
    if (isProdEnv) {
      Hotjar.init(4971697, 6);
    }
  }, []);

  const location = useLocation();

  const { isFooter } = useGetLayoutConfiguration();

  useEffect(() => {
    if (window.scrollTo) {
      window.scrollTo({ top: 0 });
    }
  }, [location.pathname]);

  return (
    <div className={style.layout}>
      <Meta title="Skip hire" />
      <Header />
      <main>{children}</main>
      {isFooter && <Footer />}
    </div>
  );
};
