import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PATHS } from 'AppPaths';
import { REACT_APP_PUBLIC_URL } from 'consts';
import { useStore } from 'effector-react';
import { useGetCommonCheckoutData } from 'hooks';
import * as CR from 'models/cart';
import { appliedPromoCode$ } from 'models/cart';
import { setOrderCompleted } from 'models/orders';
import { $UTMData } from 'models/UTMData';
import { sendMsgToSlack } from 'utils/sendMsgToSlack';

import { CHECKOUT, CRM } from 'api';

import { sendGAEvent } from '../googleAnalytics';

export const useGetOnSubmitCheckout = ({
  cardElements,
  stripe,
  subscription,
  setCardError,
  setIsSubmitSending,
  setIsWholeSectionDisabled,
}) => {
  const details = useStore(CR.details$);
  const { latestTotalPrice } = useStore(CR.latestTotalPriceInfo$);
  const checkoutPurchaseField = useStore(CR.checkoutPurchaseField$);
  const UTMData = useStore($UTMData);
  const currPayment = useRef(null);
  const appliedPromoCode = useStore(appliedPromoCode$);

  const cart = useStore(CR.cart$);
  const checkoutComments = useStore(CR.checkoutComments$);

  const history = useHistory();

  const [order, setOrder] = useState(null);
  const [errorMessage, setErrorMessage] = useState();
  const [isExpressCheckoutSuccess, setIsExpressCheckoutSuccess] =
    useState(false);

  const getFormattedCheckoutData = useGetCommonCheckoutData({
    details,
    subscription,
  });

  const getCardIntent = useCallback(
    async (order) => {
      try {
        const { data } = await CHECKOUT.pay(order.id);

        if (!stripe || !cardElements) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }

        const cardNumber = cardElements.getElement('cardNumber');
        currPayment.current = data.data?.paymentIntentId;

        return await stripe.confirmCardPayment(data.data?.clientSecret, {
          payment_method: {
            card: cardNumber,
          },
          return_url: `${REACT_APP_PUBLIC_URL}/${PATHS._SUCCESS}/`,
        });
      } catch (e) {
        console.warn(e);
        setCardError({ isError: true, message: null });
        setIsSubmitSending(false);
        setIsWholeSectionDisabled(true);

        sendMsgToSlack(
          `/pay request failed: ${
            e.response.data?.message || e.response.status
          }`,
          true,
          details.email,
        );
      }
    },
    [
      stripe,
      cardElements,
      setCardError,
      setIsSubmitSending,
      setIsWholeSectionDisabled,
      details.email,
    ],
  );

  const completeOrder = useCallback(
    (latestOrder = {}) => {
      const productPrice = latestTotalPrice?.products?.find(
        (el) => el.id === cart[0]?.id,
      );

      setOrderCompleted({
        userPromo: latestOrder.promoCode || null,
        user: {
          email: latestOrder.userEmail,
        },
        email: latestOrder.userEmail,
        subtotal: latestOrder.subtotal,
        orderId: latestOrder.id,
        orderDiscount: latestOrder.discount,
        products: cart.map((el) => ({
          ...el.product,
          total: productPrice?.subtotalWhDsWoDl || '',
          quantity: el.count,
        })),
      });

      CR.resetCart();

      history.replace({
        pathname: PATHS.SUCCESS,
        state: {
          from: 'product',
        },
      });
    },
    [cart, history, latestTotalPrice],
  );

  const payForOrder = useCallback(
    async (order, productPrice) => {
      const result = await getCardIntent(order);
      const isError = !!result.error;

      setCardError({
        isError,
        message: result.error?.decline_code || result.error?.code,
      });
      setIsWholeSectionDisabled(isError);

      if (!isError) {
        completeOrder(order);

        sendGAEvent(
          'Purchase',
          [
            {
              item_id: cart[0]?.id,
              item_name: cart[0]?.product?.name,
              coupon: appliedPromoCode,
              discount: latestTotalPrice?.discount,
              item_category: 'skip',
              location_id: cart[0]?.postcode,
              price: productPrice?.subtotal,
              quantity: cart[0].count,
              currency: 'GBP',
            },
          ],
          {
            transaction_id: currPayment.current,
            value: latestTotalPrice?.subtotal,
            tax: latestTotalPrice?.tax,
            shipping: 0,
            currency: 'GBP',
            coupon: appliedPromoCode,
          },
        );
        currPayment.current = null;
      } else {
        currPayment.current = null;

        if (latestTotalPrice?.total > 500) {
          CRM.sendTask({
            task: {
              title: 'Online payment failed',
              description: `The client unsuccessfully tried to pay online with his card. 
                          His order amount is greater than 500£. 
                          Please check the failed reason in Stripe and call this customer to inform him accordingly`,
              email: details.email,
              phone: details.phone,
              targetable_type: 'Contact',
            },
          });
        }

        sendMsgToSlack(
          `Message: ${result.error?.message || ''}; type: ${
            result.error?.type
          }; orderId: ${order.order_hash}`,
          true,
          details.email,
        );
      }
    },
    [
      getCardIntent,
      setCardError,
      setIsWholeSectionDisabled,
      completeOrder,
      cart,
      appliedPromoCode,
      latestTotalPrice,
      details.email,
      details.phone,
    ],
  );

  const getCardIntentForGoogleAndApple = useCallback(
    async (order) => {
      try {
        if (!stripe || !cardElements) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }

        const { error: submitError } = await cardElements.submit();

        if (submitError) {
          setErrorMessage(submitError.message);
          return;
        }

        const { data } = await CHECKOUT.pay(order.id);
        currPayment.current = data.data?.paymentIntentId;

        return await stripe.confirmPayment({
          elements: cardElements,
          clientSecret: data.data?.clientSecret,
          confirmParams: {
            return_url: `${REACT_APP_PUBLIC_URL}/${PATHS._SUCCESS}/`,
          },
          redirect: 'if_required',
        });
      } catch (e) {
        console.warn(e);
        setCardError({ isError: true, message: null });
        setIsSubmitSending(false);
        setIsWholeSectionDisabled(true);

        sendMsgToSlack(
          `/pay request failed: ${
            e.response.data?.message || e.response.status
          }`,
          true,
          details.email,
        );
      }
    },
    [
      stripe,
      cardElements,
      setCardError,
      setIsSubmitSending,
      setIsWholeSectionDisabled,
      details.email,
    ],
  );

  const payWithGoogleAndApple = useCallback(
    async (order, productPrice) => {
      const result = await getCardIntentForGoogleAndApple(order);

      const isError = !!result.error;

      setCardError({
        isError,
        message: result.error?.decline_code || result.error?.code,
      });
      setIsWholeSectionDisabled(isError);

      if (!isError) {
        sendGAEvent(
          'Purchase',
          [
            {
              item_id: cart[0]?.id,
              item_name: cart[0]?.product?.name,
              coupon: appliedPromoCode,
              discount: latestTotalPrice?.discount,
              item_category: 'skip',
              location_id: cart[0]?.postcode,
              price: productPrice?.subtotal,
              quantity: cart[0].count,
              currency: 'GBP',
            },
          ],
          {
            transaction_id: currPayment.current,
            value: latestTotalPrice?.subtotal,
            tax: latestTotalPrice?.tax,
            shipping: 0,
            currency: 'GBP',
            coupon: appliedPromoCode,
          },
        );
        currPayment.current = null;
        return true;
      } else {
        currPayment.current = null;

        if (latestTotalPrice?.total > 500) {
          // CRM.sendTask({
          //   task: {
          //     title: 'Online payment failed',
          //     description: `The client unsuccessfully tried to pay online with his card.
          //                 His order amount is greater than 500$.
          //                 Please check the failed reason in Stripe and call this customer to inform him accordingly`,
          //     email: details.email,
          //     phone: details.phone,
          //     targetable_type: 'Contact',
          //   },
          //   territoryId: territoryID,
          // });
        }

        sendMsgToSlack(
          `Message: ${result.error?.message || ''}; type: ${
            result.error?.type
          }; orderId: ${order.order_id}`,
          true,
          details.email,
        );
      }
    },
    [
      getCardIntentForGoogleAndApple,
      setCardError,
      setIsWholeSectionDisabled,
      latestTotalPrice,
      details.email,
    ],
  );

  const handleGoogleOrApplePay = useCallback(async () => {
    try {
      setIsSubmitSending(true);
      const productPrice = latestTotalPrice?.products.find(
        (el) => el.id === cart[0]?.id,
      );

      if (order) {
        const paymentSuccess = await payWithGoogleAndApple(order, productPrice);
        setIsExpressCheckoutSuccess(paymentSuccess);
        return;
      }

      const checkoutData = getFormattedCheckoutData();

      const { data } = await CHECKOUT.go({
        ...checkoutData,
        payment_method: checkoutData.payment_method,
        referenceNumber: checkoutPurchaseField,
        comment: checkoutComments,
        postcode: cart[0]?.postcode,
        address: cart[0]?.fullAddress,
        origin: 'wilkotoolhire.com',
        ...(UTMData && { utm: { ...UTMData } }),
      });

      const createdOrder = data?.data || {};
      setOrder(createdOrder);

      const paymentSuccess = await payWithGoogleAndApple(
        createdOrder,
        productPrice,
      );
      setIsExpressCheckoutSuccess(paymentSuccess);
    } catch (e) {
      console.warn(e);
    } finally {
      setIsSubmitSending(false);
    }
  }, [
    UTMData,
    cart,
    checkoutComments,
    checkoutPurchaseField,
    getFormattedCheckoutData,
    order,
    payWithGoogleAndApple,
    setIsSubmitSending,
  ]);

  useEffect(() => {
    if (isExpressCheckoutSuccess) {
      setIsWholeSectionDisabled(true);
      setTimeout(() => completeOrder(order), 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpressCheckoutSuccess]);

  const submitCheckoutForm = useCallback(async () => {
    const isPaymentCard = details.paymentMethod === 'card';

    const productPrice = latestTotalPrice?.products.find(
      (el) => el.id === cart[0]?.id,
    );

    try {
      setIsSubmitSending(true);

      if (order) {
        await payForOrder(order, productPrice);
        return;
      }

      const checkoutData = getFormattedCheckoutData();

      const { data } = await CHECKOUT.go({
        ...checkoutData,
        referenceNumber: checkoutPurchaseField,
        comment: checkoutComments,
        postcode: cart[0]?.postcode,
        address: cart[0]?.fullAddress,
        origin: 'wilkotoolhire.com',
        ...(UTMData && { utm: { ...UTMData } }),
      });

      const createdOrder = data?.data || {};
      setOrder(createdOrder);

      if (isPaymentCard) {
        await payForOrder(createdOrder, productPrice);
        return;
      }

      completeOrder(createdOrder);
    } catch (e) {
      console.warn(e);
      switch (e.response?.data?.slug) {
        case 'deliveryFromWarehouseNotAvailable':
        case 'deliveryNotAvailable':
          toast.error(
            'Delivery information session expired, please refresh the page',
          );
          break;
        case 'promoAlreadyRedeemed':
          toast.error('Promo code already redeemed');
          break;
        default:
          toast.error('Something went wrong');
      }
    } finally {
      setIsSubmitSending(false);
    }
  }, [
    details.paymentMethod,
    latestTotalPrice,
    cart,
    appliedPromoCode,
    setIsSubmitSending,
    order,
    getFormattedCheckoutData,
    checkoutPurchaseField,
    checkoutComments,
    UTMData,
    completeOrder,
    payForOrder,
  ]);

  return useMemo(
    () => ({ submitCheckoutForm, handleGoogleOrApplePay }),
    [handleGoogleOrApplePay, submitCheckoutForm],
  );
};
