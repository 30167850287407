import React, { lazy, useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { useSetIrclickid, useSetUTM } from 'hooks';
import { Layout } from 'layout';
import { ErrorFallback } from 'pages/ErrorFallback';
import { Main } from 'pages/Main';

import { PATHS } from './AppPaths';
import WithSuspense from './features/WithSuspense';
import { lazyRetry } from './utils/chankLoadingHandler';

import 'assets/styles/index.scss';

const Category = lazy(() =>
  lazyRetry(() => import('pages/Category'), 'Category'),
);
const Product = lazy(() => lazyRetry(() => import('pages/Product'), 'Product'));
const order = lazy(() =>
  lazyRetry(() => import('pages/order/Order'), 'checkout'),
);
const CheckoutSuccess = lazy(() =>
  lazyRetry(() => import('pages/order/CheckoutSuccess'), 'CheckoutSuccess'),
);
const FAQ = lazy(() => lazyRetry(() => import('pages/FAQ'), 'FAQ'));
const PrivacyPolicy = lazy(() =>
  lazyRetry(() => import('pages/PrivacyPolicy'), 'PrivacyPolicy'),
);
const TermsAndConditions = lazy(() =>
  lazyRetry(() => import('pages/TermsAndConditions'), 'TermsAndConditions'),
);
const CookiePolicy = lazy(() =>
  lazyRetry(() => import('pages/CookiePolicy'), 'CookiePolicy'),
);

function App() {
  useSetUTM();
  useSetIrclickid();

  const handleError = useCallback((error) => {
    const errorText = error ? error.toString() : '';
    const isErrorRelatedToChunk = /Loading .*| chunk/.test(errorText);

    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry') || 'false',
    );

    if (!isErrorRelatedToChunk) {
      return;
    }

    if (hasRefreshed) {
      console.warn('Loading chunk error happened', window.location.href);
      window.sessionStorage.setItem('retry', 'false');
    } else {
      window.sessionStorage.setItem('retry', 'true');
      window.location.reload();
    }
  }, []);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
      <Router>
        <Layout>
          <Switch>
            <Route
              path={PATHS.PRIVACY_POLICY}
              exact
              component={() => WithSuspense(PrivacyPolicy)}
            />
            <Route
              path={PATHS.TERMS_AND_CONDITIONS}
              exact
              component={() => WithSuspense(TermsAndConditions)}
            />
            <Route
              path={PATHS.COOKIE_POLICY}
              exact
              component={() => WithSuspense(CookiePolicy)}
            />
            <Route path={PATHS.FAQ} exact component={() => WithSuspense(FAQ)} />
            <Route
              path={PATHS.PRODUCT_TEMPLATE}
              exact
              component={() => WithSuspense(Product)}
            />
            <Route
              path={PATHS.CATEGORY}
              exact
              component={() => WithSuspense(Category)}
            />
            <Route
              path={[PATHS.RESERVATION, PATHS.CHECKOUT]}
              exact
              component={() => WithSuspense(order)}
            />
            <Route
              path={PATHS.SUCCESS}
              exact
              component={() => WithSuspense(CheckoutSuccess)}
            />
            <Route exact path={PATHS.ROOT}>
              <Main />
            </Route>

            <Redirect to={PATHS.ROOT} />
          </Switch>
        </Layout>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
