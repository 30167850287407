import { createEvent, createStore } from 'effector';

export const setOrderCompleted = createEvent();
export const clearOrderCompleted = createEvent();

export const $orderCompleted = createStore({
  userPromo: null,
})
  .on(setOrderCompleted, (s, p) => p)
  .on(clearOrderCompleted, (s, p) => {
    return { userPromo: null };
  });
