import { useEffect } from 'react';
import { checkUrlSearchParams, getUrlSearchParams } from 'utils/formatUrl';

export const useSetIrclickid = () => {
  useEffect(() => {
    const params = getUrlSearchParams(window.location.search);

    const formattedParams = checkUrlSearchParams(params, ['irclickid']);

    if (formattedParams?.irclickid) {
      document.cookie = `irclickid=${formattedParams.irclickid};`;
    }
  }, []);
};
