import {
  differenceInDays,
  format,
  getDay,
  isAfter,
  isSameDay,
  parse,
} from 'date-fns';
import { enGB } from 'date-fns/locale';

import { BLOCKED_DATES_LIST } from '../consts';
import { capitalizeFirstLetter } from './formatStrings';

const DAYS_OF_THE_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const getDayOfWeek = (date) => {
  return DAYS_OF_THE_WEEK[getDay(date)];
};

export const diffInDays = (dateLeft, dateRight) => {
  return differenceInDays(dateLeft, dateRight) || 1;
};

export const getTimestampsDifference = (timestamp1, timestamp2) => {
  const d1 = new Date(timestamp1);
  const d2 = new Date(timestamp2);

  const diff = d2 - d1;
  //minutes
  return Math.floor(diff / 60e3);
};

export const formatDate = (date) => {
  if (!date) return null;
  return date?.toLocaleString('en-GB').slice(0).split(',')[0];
};

export const getDateFromString = (date, format = 'dd/MM/yyyy') => {
  if (!date) return null;
  return parse(date, format, new Date());
};

export const getDateInFormat = (date, template = 'dd/MM/yyyy HH:mm') => {
  if (!date) return null;
  return format(date, template);
};

export const getLocalizedMonthLabel = (currLang, month) => {
  return enGB.localize.month(month, { width: 'wide' });
};

export const getFormattedHireDates = (
  lang = 'en-gb',
  start = '01/01/2020',
  end = '01/01/2020',
) => {
  const startDate = getDateFromString(start);
  const endDate = getDateFromString(end);

  const monthName = new Intl.DateTimeFormat(lang, { month: 'short' }).format;
  const dayOfWeekName = new Intl.DateTimeFormat(lang, { weekday: 'short' })
    .format;

  const startDateDay = new Date(startDate).getDate();
  const startDateDayOfWeek = capitalizeFirstLetter(dayOfWeekName(startDate));
  const startDateYear = new Date(startDate).getFullYear();
  const startDateMonth = capitalizeFirstLetter(monthName(new Date(startDate)));

  const endDateDay = new Date(endDate).getDate();
  const endDateDayOfWeek = capitalizeFirstLetter(dayOfWeekName(endDate));
  const endDateYear = new Date(endDate).getFullYear();
  const endDateMonth = capitalizeFirstLetter(monthName(new Date(endDate)));

  return {
    startDate: `${startDateDayOfWeek}, ${startDateMonth} ${startDateDay}, ${startDateYear}`,
    endDate: `${endDateDayOfWeek}, ${endDateMonth} ${endDateDay}, ${endDateYear}`,
  };
};

export const checkIsValidStartDate = (initialStartDate) => {
  const start = new Date();

  // check if start date is same or more then min possible start date
  // check if that date is available
  return isSameDay(initialStartDate, start) || isAfter(initialStartDate, start);
};

export const incrementDate = (date, increment) => {
  const dateFormatToTime = new Date(date);
  return new Date(dateFormatToTime.getTime() + increment * 86400000);
};

const calculateSkipDays = (
  nextDay,
  deliveryWeekends,
  blockedDatesList,
  skipDays = 1,
) => {
  if (
    !deliveryWeekends.includes(getDayOfWeek(nextDay)) &&
    !blockedDatesList.includes(getDateInFormat(nextDay, 'yyyy-MM-dd'))
  ) {
    return skipDays;
  }

  return calculateSkipDays(
    incrementDate(nextDay, 1),
    deliveryWeekends,
    blockedDatesList,
    skipDays + 1,
  );
};

export const getWithinWeekendLondonTime = () => {
  // Get current time in UTC
  const now = new Date();

  // Create options to convert UTC to London time
  const londonOptions = { timeZone: 'Europe/London', hourCycle: 'h23' };

  // Extract the date and time for London
  const londonFormatter = new Intl.DateTimeFormat('en-GB', {
    ...londonOptions,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    weekday: 'long',
    hour: 'numeric',
    minute: 'numeric',
  });

  const londonTimeParts = londonFormatter
    .formatToParts(now)
    .reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});

  const day = londonTimeParts.weekday;
  const hour = parseInt(londonTimeParts.hour, 10);
  const minute = parseInt(londonTimeParts.minute, 10);

  // Calculate conditions
  const isFridayAfter4PM = day === 'Friday' && hour >= 16;
  const isSaturday = day === 'Saturday';
  const isSundayBeforeMidnight =
    day === 'Sunday' && (hour < 24 || (hour === 23 && minute <= 59));

  return { isFridayAfter4PM, isSaturday, isSundayBeforeMidnight };
};

const getSkippedDates = () => {
  // starting from Friday 4 PM till Sunday 11:59 PM London time we should block Friday, Saturday, and Sunday
  const { isFridayAfter4PM, isSaturday, isSundayBeforeMidnight } =
    getWithinWeekendLondonTime();

  if (isFridayAfter4PM) {
    return 3;
  }

  if (isSaturday) {
    return 2;
  }

  if (isSundayBeforeMidnight) {
    return 1;
  }

  return 0;
};

export const getDatesDefault = () => {
  let skipDaysForStart = getSkippedDates();

  // Extract the date and time for London
  const startDaysCount = new Date(
    new Date().toLocaleString('en-US', {
      timeZone: 'Europe/London',
    }),
  );

  let start = incrementDate(new Date(), skipDaysForStart);

  if (BLOCKED_DATES_LIST.length) {
    //change from what day we will be skip days
    skipDaysForStart = calculateSkipDays(
      startDaysCount,
      [],
      BLOCKED_DATES_LIST,
      skipDaysForStart,
    );

    start = incrementDate(new Date(), skipDaysForStart);
  }

  return start;
};
