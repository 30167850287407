import React from 'react';
import clsx from 'clsx';

import { EnquiryLead } from 'features/EnquiryLead';
import { Meta } from 'features/Meta';

import { Benefits } from './Benefits';
import { HassleFreeBooking } from './HassleFreeBooking';
import { HeroBanner } from './HeroBanner';
import { HireSkip } from './HireSkip';
import { PoweredByBanner } from './PoweredByBanner';

import style from './main.module.scss';

export const Main = () => {
  return (
    <div>
      <Meta title="Home - Skip hire" />
      <PoweredByBanner />
      <HeroBanner />
      <HassleFreeBooking />

      <div className={clsx(style.mainPageContentWrapper, style.skipsCarousel)}>
        <HireSkip />
      </div>

      <div
        className={clsx(style.mainPageContentWrapper, style.benefitsWrapper)}
      >
        <Benefits />
      </div>

      {/*<EnquiryLead source="Contact form (index page)" />*/}
    </div>
  );
};
