import React, { useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { setIsMenuOpen } from 'models/common';

import Hamburger from '../Hamburger';
import SubMenuCompany from '../SubMenuCompany';
import SubMenuMobile from '../SubMenuMobile';

import { T8y } from 'ui';

import style from './mobileMenu.module.scss';

const MobileNav = ({ openId, setOpenId, isMenuOpen, close, isSearchOpen }) => {
  const toggleMenu = useCallback(
    (e) => {
      if (!e.target) {
        return;
      }

      if (e.target.classList.contains('disable-scrolling')) {
        close();
      }
    },
    [close],
  );

  useEffect(() => {
    if (isMenuOpen) {
      document.querySelector('body').classList.add('disable-scrolling');

      document.addEventListener('click', toggleMenu);
    } else {
      document.querySelector('body').classList.remove('disable-scrolling');

      document.removeEventListener('click', toggleMenu);
      openId === 4 && setOpenId(0);
    }
    return () => {
      document.querySelector('body').classList.remove('disable-scrolling');

      document.removeEventListener('click', toggleMenu);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen]);

  const handleMobileMenuClick = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);

  return (
    <>
      <Hamburger open={isMenuOpen} onClick={handleMobileMenuClick} />
      <div
        className={clsx(
          style.mobileMenu,
          isMenuOpen && style.open,
          isSearchOpen && style.mobileMenuSearchOpen,
        )}
      >
        <div className={style.linksWrapper}>
          <SubMenuMobile
            id={1}
            openId={openId}
            setOpenId={setOpenId}
            close={close}
          />
          <SubMenuCompany
            id={3}
            openId={openId}
            setOpenId={setOpenId}
            close={close}
          />
          {openId === 0 && (
            <>
              <T8y
                as="a"
                href="https://www.wilko.com"
                rel="noopener noreferrer"
                className={style.backToWilkoLink}
              >
                Back to wilko.com
              </T8y>

              <T8y
                className={style.mobilePhoneLink}
                as="a"
                href="tel:+441146972495"
                noWrap
              >
                <span>Call Free</span> <span>+44 1146 972495</span>
              </T8y>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MobileNav;
